.tooltip {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  box-shadow: -3px 3px 15px #888;
  color: white;
  padding: 6px;
}

.svgContent {
  display: inline-block;
	position: absolute;
	top: 0;
	left: 2.5%;
  width: 100%;
  height: 100%;
}

.bigSvgContent {
  display: inline-block;
	position: absolute;
	top: calc(235px - 15vw);
	left: 0;
  width: 100%;
  height: 100%;
}
.bigSvgContentForPdf {
  display: inline-block;
	position: absolute;
	top: calc(235px - 15vw);
	left: 0;
  width: 100%;
  height: 100%;
  margin-top: -40px;
}

@media (min-width: 1200px) and (max-width: 1536px) {
  .svgContent {
    top: calc(160px - 10vw) !important;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .svgContent {
    left: 25% !important;
  }
  .bigSvgContent {
    top: calc(260px - 20vw)
  }
}