.tooltip {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  box-shadow: -3px 3px 15px #888;
  color: white;
  padding: 6px;
}
.cardSvgContent {
  display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) and (max-width: 1600px)  {
  .cardSvgContent {
    top: calc(200px - 100vw/8)
  }
}

