.y-axis-secondary .domain {
	visibility: hidden;
}

.y-axis-stage .domain {
	visibility: hidden;
}
.y-axis-pdf {
  font-size: 16px;
}
.x-axis-pdf {
  font-size: 16px;
}

.y-axis-secondary-pdf {
  font-size: 16px;
}
.y-axis-secondary-pdf .domain {
  visibility: hidden;
}

.tooltip {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  box-shadow: -3px 3px 15px #888;
  color: white;
  padding: 6px;
}
.lineChartSvgContent, .pieChartSvgContent {
  display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
  width: 100%;
  height: 100%;
}